const DSBridge = require('dsbridge');
/**
 * 获取token
 */
export const getToken = () => {
  return new Promise((resolve) => {
    let token = DSBridge.call('getToken');
    resolve(token);
  });
};

export const videoPlayerOnFullScreen = (flag) => {
  DSBridge.call('onFullScreen', flag);
};
