<template>
  <router-view/>
</template>

<style>
body,html,head{
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}
img{
   -webkit-touch-callout: none !important;
}
</style>
