import axios from 'axios';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { toast } from './utils/toast';
import api from './api/index';
const DSBridge = require('dsbridge');
axios.defaults.baseURL = '/api/app/v1';

const app = createApp(App);
app.use(router);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$toast = toast;
app.config.globalProperties.$bridge = DSBridge;
app.config.globalProperties.$api = api;
app.mount('#app');
