import { getToken } from '../utils/index';
let token = getToken();

const api = {
  GetVideoUrl(data) {
    return this.$axios.get('/api/app/device/get-video-url', {
      params: data,
      headers: {
        token,
      },
    });
  },
};
export default api;
